const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.newcrest.forwoodsafety.com',
    APP_CLIENT_ID: '63us0chtv6f6ccrv95d3pbk0mc',
    USER_TOKEN_URL: 'https://9pn7h5qjva.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'ap-southeast-2',
    URL: 'https://u7780dpsr9.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.newcrest.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.newcrest.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.newcrest.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.newcrest.forwoodsafety.com',
    WEBSOCKET: 'wss://okh6i0qqgi.execute-api.ap-southeast-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;